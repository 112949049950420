import React from "react";
import axios from 'axios'
import { loginUrl, removeNotificationsUrl } from '../Common/Global';
import { logError, encodePass } from "./utils";

type UserDataInterface = {
  initialized: boolean;
  loggedIn: boolean;
  user: any;
};

// create the context
export const AuthContext = React.createContext<any>(undefined);

// create the context provider, we are using use state to ensure that
// we get reactive values from the context...
// eslint-disable-next-line
type Props = {
  children: React.ReactNode;
};
export const AuthProvider: React.FC<any> = ({ children }: { children: any }) => {
  // the reactive values
  const [authInfo, setAuthInfo] = React.useState<UserDataInterface>();

  const logOut = (SecureURL: string) => {
    let theFrame=document.getElementsByTagName('iframe')[0];
    theFrame.src = SecureURL + "Account/ExternalLogoff";

    return new Promise((resolve) => {

      const devicePost = {
        DeviceID: localStorage.getItem("deviceId"),
        RegistrationID: localStorage.getItem("FCMToken"),
        DeviceInfo: localStorage.getItem("deviceInfo")
      }
      const userAuthData = localStorage.getItem("AUTH") + "";
      const userData = JSON.parse(userAuthData);

      let config = {
        headers: { Authorization: `Bearer ${userData.access_token}` }
      }
      axios.post(removeNotificationsUrl, devicePost, config).then((response) => {
        window.localStorage.removeItem("AUTH");
        window.localStorage.removeItem("FCMToken");
        setAuthInfo({ initialized: true, loggedIn: false, user: null });
        return resolve(true);
      }).catch((error) => {
        logError(error, "LogOut");
        window.localStorage.removeItem("AUTH");
        window.localStorage.removeItem("FCMToken");
        setAuthInfo({ initialized: true, loggedIn: false, user: null });
        return resolve(true);
      });
    });
  };

  const logIn = (email: string, password: string) => {
    return new Promise(async (resolve, reject) => {

      // 2023-06-10 mdi encoded password
      const encodedPass = encodePass(password); 

      const thePost = `username=${email}&password=${encodedPass}&grant_type=password`;

      //console.log("Log in post:", thePost);

      let resp: UserDataInterface = {
        initialized: true,
        loggedIn: false,
        user: null
      };

      axios.post(loginUrl, thePost).then(response => {
        //console.log("Response:", response);
        resp.loggedIn = true;
        resp.user = response.data;

        setAuthInfo(resp);
        //console.log(resp);
        window.localStorage.setItem("AUTH", JSON.stringify(resp.user));

        //  2023-01-19  hmw Removed the timeout
        /*
        setTimeout(() => {
          return resolve(true);
        }, 1000);
        */
        return resolve(true);
      }).catch(error => {
        console.log("Log in Error:", error.response.data.error_description);

        //  2023-01-19  hmw Removed the timeout
        /*
        setTimeout(() => {
          return reject(error.response.data.error_description)
        }, 1000);
        */
        return reject(error.response.data.error_description)

      });
    });
  };

  const initialize = () => {

    //  2023-01-19  hmw Moved to index.tsx
    /*
    //  2023-01-09  hmw Modified to not set on Event Name if already stored
    //
    const EventName = localStorage.getItem('EventName') || 'pmc'
    const EventDesc = localStorage.getItem('EventDesc') || 'PMC 2023'

    localStorage.setItem('EventName', EventName);
    localStorage.setItem('EventDesc', EventDesc);
    */

    let response = window.localStorage.getItem("AUTH") || null;
    if (response !== null) {
      //let userObj = JSON.parse(response);
      //debugger;
      //console.log("Not NUll:", response);
      setAuthInfo({
        initialized: true,
        loggedIn: true,
        user: JSON.parse(response)
      });
    } else {
      //console.log("Null:", response);
      setAuthInfo({
        initialized: true,
        loggedIn: false,
        user: null
      });
    }
  };

  let v = {
    authInfo,
    logOut: logOut,
    logIn: logIn,
    initialize,
  };

  return <AuthContext.Provider value={v}>{children}</AuthContext.Provider>;
};

export const useAuth = () => React.useContext(AuthContext);
